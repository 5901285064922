<template>
    <button @click.stop="toggleFaq" class="focus:outline-none outline-none duration-200 bg-white flex flex-col w-full justify-center max-w-[700px] gap-5 rounded-lg shadow-center p-1.5">
        <div  class="mt-auto flex flex-nowrap gap-3 items-center">
            <div class="aspect-square w-10 flex justify-center items-center">
                <svg :class="{'rotate-180 max-md:group-hover:text-primary': isOpen}" class="max-md:group-hover:text-black duration-200"  xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 24 24"><g class="text-inherit" fill="none" fill-rule="evenodd"><path class="text-inherit" d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path class="text-inherit" fill="currentColor" d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"/></g></svg>
            </div>

            <span class="duration-200 text-lg font-medium text-left max-md:text-base">{{ question }}</span>
        </div>
        <div v-if="isOpen" :class="{'max-h-0 hidden opacity-0': !isOpen, 'max-h-none opacity-100': isOpen}" class="px-3 py-1 text-left duration-1000 overflow-hidden"> {{ answer }}</div>
    </button>
</template>

<script setup>
import { ref } from "vue"

    const props = defineProps({
        question: {
            type: String,
            default: ""
        },
        answer: {
            type: String,
            default: ""
        },
    })

    const isOpen = ref(false)

    const toggleFaq = () => {
        isOpen.value = !isOpen.value
    }
</script>
