<template>
    <!-- Backdrop -->
    <button v-if="isOpen" @click.self="addAnimToModal" :class="{'scaleModal': animToModal}" class="items-start pt-[20vh] z-[1000] top-0 left-0 w-full h-screen fixed bg-neutral-950/60 flex justify-center p-3">

        <!-- Modal -->
        <div :class="{'fadeToAppear': isOpen}" class="relative z-[1000] w-full max-w-[500px] h-fit bg-white rounded-md flex flex-col justify-between gap-5">

            <!-- Header -->
            <div class="w-full flex justify-between items-center border-b p-3">
                <div :class="{'text-green-500': isSuccess, 'text-red-500': !isSuccess}" class="text-xl font-semibold max-sm:text-base">{{ modalHeader }}</div>
                <button @click.stop="closeModal" class="md:hover:bg-neutral-100 duration-200 rounded-full p-1">
                    <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="text-inherit" fill="currentColor" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"/></svg>
                </button>
            </div>

            <!-- Body -->
            <div class="w-full h-full p-3">
                <div class="text-left font-medium">
                    {{ modalBody }}
                </div>
            </div>

            <!-- Footer -->
            <div class="border-t w-full p-3 flex justify-end">
                <button  @click.stop="closeModal" class="bg-neutral-400 hover:bg-neutral-400/80 duration-200 w-fit min-w-20 p-1.5 text-white rounded-md">{{ buttonText }}</button>
            </div>
        </div>
    </button>
</template>

<script setup>
import { ref } from "vue"

const animToModal = ref(false)

const emits = defineEmits(['closeModal'])
const props = defineProps({
    modalHeader: {
        type: String,
        default: "Modal Header"
    },
    modalBody: {
        type: String,
        default: "Modal Body"
    },
    buttonText: {
        type: String,
        default: "Close"
    },
    isSuccess: {
        type: Boolean,
        default: true
    },
    isOpen: {
        type: Boolean,
        default: false
    }
})

const closeModal = () => {
    emits('closeModal')
}

const addAnimToModal = () => {
    if(animToModal.value) return
    animToModal.value = true

    setTimeout(() => {
        animToModal.value = false
    }, 500)
}
</script>