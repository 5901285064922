<template>
    <div id="about" class="max-lg:gap-10 max-lg:grid-cols-1 w-full grid grid-cols-1 gap-10 px-10 relative max-md:px-3">
        <!-- <img class="max-lg:hidden absolute left-0 top-0 w-1/2 h-full object-contain object-center rounded-r-3xl border-primary border-4 border-l-0" src="@/assets/about.png" alt=""> -->

        <!-- <div class="max-lg:max-w-[700px] mx-auto">
            <img class="max-lg:flex  w-full object-contain object-center rounded-xl border-primary border-4" src="@/assets/about.png" alt="">
        </div> -->
        <div v-motion-slide-visible-bottom v-if="data" class="gap-10 aspect-[5/5.5] border-4 border-primary bg-white max-md:p-1 max-md:border-none p-10 rounded-xl w-full flex flex-col justify-around max-w-[700px] mx-auto">
            <h2 class="z-20 max-md:text-center max-md:px-5 text-balance w-full text-4xl font-semibold text-left">
                About <span class="text-primary">us</span>
            </h2>
            
            <div v-if="data.who.length !=0" class="z-20">
                <h3 class="font-semibold text-primary text-left mb-1">
                    Who are we?
                </h3>
                <template v-for="paragraph, index in data.who" :key="index">
                    <p class="text-justify">
                        {{ paragraph }}
                    </p>
                    <br v-if="index != data.who.length-1">
                </template>
            </div>

            <div v-if="data.vision.length !=0" class="z-20">
                <h3 class="font-semibold text-primary text-left mb-1">
                    Our vision
                </h3>
                <template v-for="paragraph, index in data.vision" :key="index">
                    <p class="text-justify">
                        {{ paragraph }}
                    </p>
                    <br v-if="index != data.vision.length-1">
                </template>
            </div>

            <div v-if="data.mission.length !=0" class="z-20">
                <h3 class="font-semibold text-primary text-left mb-1">
                    Our Mission
                </h3>
                <template v-for="paragraph, index in data.mission" :key="index">
                    <p class="text-justify">
                        {{ paragraph }}
                    </p>
                    <br v-if="index != data.mission.length-1">
                </template>
            </div>
        </div>

        <div v-else class="max-md:gap-2 w-full grid grid-cols-1 gap-5 place-content-start max-w-[700px] mx-auto">
            <h2 class="max-md:h-8 max-md:w-1/2 w-1/3 h-12 bg-neutral-200 animate-pulse rounded-md"></h2>
            
            <div class="flex flex-col gap-2">
                <div class="max-md:h-5 w-1/4 h-8 bg-primary/40 animate-pulse rounded-md"></div>
                <div class="max-md:h-28 w-full h-32 bg-neutral-200 animate-pulse rounded-md"></div>
            </div>

            <div class="flex flex-col gap-2">
                <div class="max-md:h-5 w-1/5 h-8 bg-primary/40 animate-pulse rounded-md"></div>
                <div class="max-md:h-32 w-full h-40 bg-neutral-200 animate-pulse rounded-md"></div>
            </div>

            <div class="flex flex-col gap-2">
                <div class="max-md:h-5 w-1/4 h-8 bg-primary/40 animate-pulse rounded-md"></div>
                <div class="max-md:h-16 w-full h-24 bg-neutral-200 animate-pulse rounded-md"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGetResource } from '@/composables/useGetResource';
import { useGetResourcesLinks } from '@/composables/useGetResourcesLinks';
import { onMounted, ref } from 'vue';

const { resourcesLinks } = useGetResourcesLinks()
const { getData } = useGetResource(resourcesLinks.aboutUrl)
const data = ref(null)

onMounted( async () => {
    data.value = await getData()
})
</script>