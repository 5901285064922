export function useGoTo(){
    const goTo = (id) => {
        let topPosition = document.getElementById(id).offsetTop - 120;
        window.scrollTo({
            top: topPosition,
            left: 0,
            behavior: "smooth",
        });
    }

    return { goTo }
};