<template>
    <div id="testimonials" class="w-full flex flex-col px-2 gap-5">
        <div class="max-lg:flex-col gap-5 w-full flex justify-between max-w-[1200px] mx-auto px-10">
            <template v-if="data">
                <h2 class="max-lg:text-center max-lg:px-5 text-balance w-full text-4xl font-semibold text-left">
                    What our <span class="text-primary">clients</span> say
                </h2>
                <div class="max-lg:justify-center flex justify-end gap-5">
                    <button @click="navSlide(-1)" class="max-lg:hidden focus:outline-none hover:text-primary text-neutral-200">
                        <svg class="text-inherit" xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 64 64"><path class="duration-300 text-inherit"  fill="currentColor" d="M32 2C15.432 2 2 15.432 2 32c0 16.568 13.432 30 30 30s30-13.432 30-30C62 15.432 48.568 2 32 2m17 35.428H30.307V48L15 32l15.307-16v11.143H49z"/></svg>
                    </button>
                    <button @click="navSlide(1)" class="max-lg:hidden focus:outline-none hover:text-primary text-neutral-200">
                        <svg class="text-inherit rotate-180" xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 64 64"><path class=" duration-300 text-inherit"  fill="currentColor" d="M32 2C15.432 2 2 15.432 2 32c0 16.568 13.432 30 30 30s30-13.432 30-30C62 15.432 48.568 2 32 2m17 35.428H30.307V48L15 32l15.307-16v11.143H49z"/></svg>
                    </button>
                </div>
            </template>

            <template v-else>
                <h2 class="w-full bg-neutral-200 animate-pulse rounded-md max-w-96 h-14"></h2>
                <div class="max-lg:justify-center flex justify-end gap-5">
                    <div class="max-lg:hidden aspect-square w-14 h-14 rounded-full animate-pulse bg-primary/40"></div>
                    <div class="max-lg:hidden aspect-square w-14 h-14 rounded-full animate-pulse bg-primary/40"></div>
                </div>
            </template>
        </div>
        
        <carousel ref="myCarousel" :pauseAutoplayOnHover="true" :items-to-show="3.5" :wrap-around="true" :breakpoints="breakpoints" :autoplay="data ? 5000 : null" :transition="300">
            <template v-if="data" >
                <slide v-for="testimonial, index in data.testimonials" :key="index">
                    <TestimonialCard :name="testimonial.name" :content="testimonial.content"/>
                </slide>
            </template>

            <template v-else >
                <slide v-for="i in 5" :key="i">
                    <div class=" w-full max-w-[400px]  aspect-[8/10] rounded-md bg-neutral-200 animate-pulse"></div>
                </slide>
            </template>
        
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>
    </div>

    
  </template>
  
<script setup>
import TestimonialCard from './TestimonialCard.vue';
import { useGetResource } from '@/composables/useGetResource';
import { useGetResourcesLinks } from '@/composables/useGetResourcesLinks';
import { onMounted, ref } from 'vue';

const { resourcesLinks } = useGetResourcesLinks()
const { getData } = useGetResource(resourcesLinks.testimonialsUrl)
const data = ref(null)

onMounted(async () => {
    data.value = await getData()
})


// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

const myCarousel = ref(null)

// const testimonials = [
//     { name: "Johnny", content: "Wow, hindi ko akalain na ganito kaganda ang magiging resulta ng decking service na ito! Malinis at maayos ang pagkakalatag ng mga materyales. Worth it talaga sa Deck Solutions! " },
//     { name: "Johnny", content: "Wow, hindi ko akalain na ganito kaganda ang magiging resulta ng decking service na ito! Malinis at maayos ang pagkakalatag ng mga materyales. Worth it talaga sa Deck Solutions! " },
//     { name: "Johnny", content: "Wow, hindi ko akalain na ganito kaganda ang magiging resulta ng decking service na ito! Malinis at maayos ang pagkakalatag ng mga materyales. Worth it talaga sa Deck Solutions! " },
//     { name: "Johnny", content: "Wow, hindi ko akalain na ganito kaganda ang magiging resulta ng decking service na ito! Malinis at maayos ang pagkakalatag ng mga materyales. Worth it talaga sa Deck Solutions! " },
//     { name: "Johnny", content: "Wow, hindi ko akalain na ganito kaganda ang magiging resulta ng decking service na ito! Malinis at maayos ang pagkakalatag ng mga materyales. Worth it talaga sa Deck Solutions! " },
//     { name: "Johnny", content: "Wow, hindi ko akalain na ganito kaganda ang magiging resulta ng decking service na ito! Malinis at maayos ang pagkakalatag ng mga materyales. Worth it talaga sa Deck Solutions! " },
// ];

const navSlide = (val) => {
    if (val > 0) myCarousel.value.next()
    else myCarousel.value.prev()
}

const breakpoints = ref(
    {   
        100: {
            itemsToShow: 1,
            snapAlign: 'start',
        },
        700: {
            itemsToShow: 1.5,
            snapAlign: 'center',
        },
        900: {
            itemsToShow: 2,
            snapAlign: 'start',
        },
        1100: {
            itemsToShow: 2.5,
            snapAlign: 'center',
        },
        1250: {
            itemsToShow: 3,
            snapAlign: 'center',
        },
        // 1024 and up
        1500: {
            itemsToShow: 3.5,
            snapAlign: 'center',
        }
    }
) 


    
</script>

<style>

    .carousel__slide {
    padding: 5px;
    }
    
    .carousel__pagination-button::after{
        background-color: rgb(240, 237, 237);
        border-radius: 500px;
        width: 8px;
        height: 8px;
        animation-duration: 200ms;
    }

    .carousel__pagination-button:hover::after{
        background-color: #dddddc !important;
    }

    .carousel__pagination-button--active::after{
        background-color: #FEA031;
        animation-duration: 200ms;
    }

    .carousel__pagination-button--active:hover::after{
        background-color: #f7b973 !important;
    }

  </style>
  