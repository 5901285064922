<template>
    <div class="select-none m-5 w-full h-full max-w-[400px] flex flex-col justify-between mx-auto rounded-xl shadow-center ">
        <div class="flex justify-start px-10 pt-10 ">
            <svg class="max-md:w-10 w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1664 1408"><path fill="#EEEEEE" d="M768 832v384q0 80-56 136t-136 56H192q-80 0-136-56T0 1216V512q0-104 40.5-198.5T150 150T313.5 40.5T512 0h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136m896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V512q0-104 40.5-198.5T1046 150t163.5-109.5T1408 0h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136"/></svg>
        </div>

        <div class="text-left leading-8 px-10 italic" :title="content">
            {{ truncate(content, 250) }}
        </div>

        <div class="flex justify-end px-10 pb-10 ">
            <svg class="max-md:w-10 rotate-180 w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1664 1408"><path fill="#EEEEEE" d="M768 832v384q0 80-56 136t-136 56H192q-80 0-136-56T0 1216V512q0-104 40.5-198.5T150 150T313.5 40.5T512 0h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136m896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V512q0-104 40.5-198.5T1046 150t163.5-109.5T1408 0h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136"/></svg>
        </div>

        <div class="w-full bg-primary text-white rounded-b-xl p-3 py-5 flex flex-col gap-3">
            <div class="flex flex-nowrap gap-2 justify-center items-center">
                <svg v-for="i in 5" :key="i" xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 64 64"><path fill="#ffce31" d="M62 25.2H39.1L32 3l-7.1 22.2H2l18.5 13.7l-7 22.1L32 47.3L50.5 61l-7.1-22.2z"/></svg>
            </div>
            <div class="flex flex-nowrap justify-center gap-2">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 26 26"><g fill="none"><defs><mask id="pepiconsPopPersonCircleFilled0"><path fill="#fff" d="M0 0h26v26H0z"/><g fill="#000"><path fill-rule="evenodd" d="M9 9a4 4 0 1 0 8 0a4 4 0 0 0-8 0m6 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0" clip-rule="evenodd"/><path d="M20 21a1 1 0 1 1-2 0v-2.5c0-2.494-2.206-4.5-4.984-4.5C10.23 14 8 16.013 8 18.5l.002 2.5a1 1 0 1 1-2 0L6 18.5c0-3.64 3.169-6.5 7.016-6.5C16.86 12 20 14.857 20 18.5z"/></g></mask></defs><circle cx="13" cy="13" r="13" fill="white" mask="url(#pepiconsPopPersonCircleFilled0)"/></g></svg>
                </div>
                <div class="flex flex-col text-white">
                    <span class="text-inherit text-left font-bold">{{ name }}</span>
                    <span class="text-inherit text-left">Satisfied Client</span>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
        name: {
            type: String
        },
        content: {
            type: String
        }
    })

const truncate = (str, limit) => {
    if(str.length > limit){
        return str.slice(0, limit) + "..."
    }
    return str
}
</script>