<template>
    <div id="FAQ" class="relative w-full">
        <div v-if="data" class="w-full max-w-[1200px] flex flex-col justify-start items-center mx-auto gap-10 px-3">
            <h2 class="max-md:px-5 text-balance w-full text-4xl font-semibold text-center">
                Frequently Asked <span class="text-primary">Questions</span>
            </h2>
            <div class="w-full grid grid-cols-1 gap-7 place-content-center place-items-center">
                <FaqBlock v-for="faq, index in data.faqs" :key="index" :question="faq.question" :answer="faq.answer"/>
            </div>
            <!-- <div v-else class="w-full grid grid-cols-1 gap-7 place-content-center place-items-center">
                <div v-for="i in 5" :key="i" class="w-full h-14 rounded-md bg-neutral-200 animate-pulse max-w-[700px]"></div>
            </div> -->
        </div>
        <div v-else class="w-full max-w-[1200px] flex flex-col justify-start items-center mx-auto gap-7 px-2">
            <h2 class="w-full max-w-[600px] h-16 bg-neutral-200 animate-pulse rounded-md"></h2>
            <div class="w-full grid grid-cols-1 gap-3 place-content-center place-items-center">
                <div v-for="i in 3" :key="i" class="w-full h-14 rounded-md bg-neutral-200 animate-pulse max-w-[700px]"></div>
            </div>
        </div>

        <!-- <img class="rotate-180 rounded-xl max-sm:hidden opacity-20 w-full h-[80vh] object-fit object-center absolute left-0 -top-[5%] -z-10" src="./../../../assets/warp2.png" alt=""> -->
    </div>
</template>

<script setup>
import { useGetResource } from '@/composables/useGetResource';
import { useGetResourcesLinks } from '@/composables/useGetResourcesLinks';
import { onMounted, ref } from 'vue';
import FaqBlock from './FaqBlock.vue';

const { resourcesLinks } = useGetResourcesLinks()
const { getData } = useGetResource(resourcesLinks.faqsUrl)
const data = ref(null)

onMounted(async () => {
    data.value = await getData()
})
// const faqs = [
//     {
//         question: "How long does it take to get a quote?",
//         answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//     },
//     {
//         question: "How long does it take to get a quote?",
//         answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//     },
//     {
//         question: "How long does it take to get a quote?",
//         answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//     },
//     {
//         question: "How long does it take to get a quote?",
//         answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//     },
//     {
//         question: "How long does it take to get a quote?",
//         answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//     },
// ]
</script>