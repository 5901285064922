<template>
  <nav
    class="z-[100] w-full backdrop-blur-[15px] shadow-md fixed top-0 left-0 px-5 py-3"
  >
    <div
      class="relative flex flex-nowrap items-center justify-between mx-auto w-full max-w-[1200px]"
    >
      <!-- Logo -->
      <button
        @click="goTo('overview')"
       class="focus:outline-none outline-none w-fit flex flex-col">
        <span class="font-bold text-2xl text-primary">AMC</span>
        <span class="font-lg font-medium text-primary"
          >Vinyl Decking and Railing</span
        >
      </button>

      <!-- Desktop Nav -->
      <div
        class="max-md:hidden flex flex-nowrap gap-10 justify-between items-center"
      >
        <button
          @click="goTo('services')"
          class="hover:text-primary text-primary duration-200 group font-semibold flex flex-col justify-center items-center"
        >
          <span class="text-inherit">Services</span>
          <div
            class="group-hover:w-full h-0.5 w-0 bg-primary duration-200 rounded-full"
          ></div>
        </button>
        <button
          @click="goTo('about')"
          class="hover:text-primary text-primary duration-200 group font-semibold flex flex-col justify-center items-center"
        >
          <span class="text-inherit">About</span>
          <div
            class="group-hover:w-full h-0.5 w-0 bg-primary duration-200 rounded-full"
          ></div>
        </button>
        <button
          @click="goTo('footer')"
          class="hover:text-primary text-primary duration-200 group font-semibold flex flex-col justify-center items-center"
        >
          <span class="text-inherit">Contacts</span>
          <div
            class="group-hover:w-full h-0.5 w-0 bg-primary duration-200 rounded-full"
          ></div>
        </button>
        <button
          @click="goTo('quote')"
          class="hover:bg-[#ED952D] group duration-200 group h-10 font-semibold flex flex-nowrap gap-2 items-center justify-center bg-primary px-5 rounded-full text-white"
        >
          <span class="text-inherit">Get a Quote</span>
          <svg
            class="group-hover:translate-x-2 duration-200 text-inherit"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 24 24"
          >
            <g fill="none" fill-rule="evenodd">
              <path
                d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
              />
              <path
                fill="white"
                d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
              />
            </g>
          </svg>
        </button>
      </div>

      <!-- Mobile Nav -->
      <button
        @click.stop="toggleShowMobileNavContent"
        v-if="!desktopMode"
        class="focus:outline-none burger-container flex items-center justify-center"
      >
        <div class="custom-burger"></div>
      </button>

      <!-- Mobile Nav Content -->
      <div
        v-show="!desktopMode"
        :class="{
          fadeIn: !desktopMode && showMobileNavContent,
          fadeOut: !desktopMode && !showMobileNavContent,
        }"
        class="absolute right-0 top-20 bg-white rounded-lg shadow-center w-64 flex-col gap-5 p-5"
      >
        <button
          @click="goTo('services')"
          class="hover:text-primary duration-200 group font-semibold flex flex-col justify-center items-center"
        >
          <span class="text-inherit">Services</span>
        </button>
        <button
          @click="goTo('about')"
          class="hover:text-primary duration-200 group font-semibold flex flex-col justify-center items-center"
        >
          <span class="text-inherit">About</span>
        </button>
        <button
          @click="goTo('footer')"
          class="hover:text-primary duration-200 group font-semibold flex flex-col justify-center items-center"
        >
          <span class="text-inherit">Contacts</span>
        </button>
        <button
          @click="goTo('quote')"
          class="hover:bg-[#ED952D] group duration-200 group h-10 font-semibold flex flex-nowrap gap-2 items-center justify-center bg-primary px-5 rounded-full text-white"
        >
          <span class="text-inherit">Get a Quote</span>
          <svg
            class="group-hover:translate-x-2 duration-200 text-inherit"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 24 24"
          >
            <g fill="none" fill-rule="evenodd">
              <path
                d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
              />
              <path
                fill="white"
                d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useGetWindowWidth } from "@/composables/useGetWindowWidth";
import { computed, onMounted, ref } from "vue";
import { useGoTo } from "@/composables/useGoTo";

const { windowWidth } = useGetWindowWidth();
const { goTo } = useGoTo();

const showMobileNavContent = ref(false);

const desktopMode = computed(() => {
  if (windowWidth.value >= 768) return true;
  else return false;
});

const toggleShowMobileNavContent = () => {
  showMobileNavContent.value = !showMobileNavContent.value;
};

onMounted(() => {
  window.addEventListener("click", () => {
    if (showMobileNavContent.value) toggleShowMobileNavContent();
  });
});


</script>
