export function useGetResourcesLinks(){
    
    // DO NOT CHANGE MANUALLY, CHANGE .ENV FILE INSTEAD(CREATE ONE IF IT DOES NOT EXIST @ ROOT DIRECTORY).
    // SEE .ENV.EXAMPLE FOR REQUIRED VARIABLES
    const baseUrl = process.env.VUE_APP_API_URL

    const resourcesLinks = 
        {
            aboutUrl: baseUrl + "about/about.json",
            faqsUrl: baseUrl + "faqs/faqs.json",
            addressUrl: baseUrl + "info/address.json",
            emailUrl: baseUrl + "info/email.json",
            phoneUrl: baseUrl + "info/phone.json",
            socialsUrl: baseUrl + "info/socials.json",
            testimonialsUrl: baseUrl + "testimonials/testimonials.json",
            galleryUrl: baseUrl + "gallery/gallery.json",
        }
    

    return {
        resourcesLinks
    }
}