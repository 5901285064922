<template>
    <div id="quote" class="max-md:flex-col max-md:gap-10 flex flex-nowrap max-w-[1200px] w-full mx-auto justify-between px-5 gap-5">
        <div class="max-md:w-full flex flex-col gap-7 w-1/2">
            <div class="w-full flex flex-col gap-7">
                <h2 class="max-md:text-center max-md:px-5 text-balance w-full text-4xl font-semibold text-left">
                    Get a <span class="text-primary">Quote</span>
                </h2>
                <p class="max-md:max-w-none max-md:text-left w-full text-pretty max-w-96 text-neutral-400">
                    Elevate your outdoor space with our expert decking services! Get a quote today for a beautiful, durable, and customized deck tailored to your needs and budget.
                </p>
            </div>

            <div class="w-fit mr-auto">
                <ul class="max-md:flex-row max-md:flex-wrap max-w-[300px] flex flex-col gap-5">
                    <li class="text-lg flex gap-2 items-center">
                        <div class="">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2rem"
                            height="2rem"
                            viewBox="0 0 24 24"
                            class="text-black"
                            >
                            <path
                                class="text-inherit"
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                                clip-rule="evenodd"
                            />
                            </svg>
                        </div>
                        <span class="w-full text-wrap font-semibold"
                            >Quick Response</span
                        >
                    </li>
                    <li class="text-lg flex gap-2 items-center">
                        <div class="">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2rem"
                            height="2rem"
                            viewBox="0 0 24 24"
                            class="text-black"
                            >
                            <path
                                class="text-inherit"
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                                clip-rule="evenodd"
                            />
                            </svg>
                        </div>
                        <span class="w-full text-wrap font-semibold"
                            >Reliable Quotes</span
                        >
                    </li>
                    <li class="text-lg flex gap-2 items-center">
                        <div class="">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2rem"
                            height="2rem"
                            viewBox="0 0 24 24"
                            class="text-black"
                            >
                            <path
                                class="text-inherit"
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                                clip-rule="evenodd"
                            />
                            </svg>
                        </div>
                        <span class="w-full text-wrap font-semibold"
                            >Reliable Work</span
                        >
                    </li>
                </ul>
            </div>
        </div>

        <form action="" @submit.prevent="submitForm" class="max-md:w-full w-1/2 flex flex-col gap-10 max-md:gap-7">
            <fieldset class="grid grid-cols-2 gap-10 max-sm:grid-cols-1 max-md:gap-7">
                <div class="flex flex-col">
                    <label for="firstName">First Name <span class="text-red-400">*</span></label>
                    <input id="firstName" @input="clearWarning(firstNameWarning)" v-model.trim="firstName" class="focus:outline-primary border-2 rounded-sm px-2 py-1" type="text">
                    <span ref="firstNameWarning" class="text-sm text-red-400 font-medium"></span>
                </div>

                <div class="flex flex-col">
                    <label for="lastName">Last Name <span class="text-red-400">*</span></label>
                    <input id="lastName" @input="clearWarning(lastNameWarning)" v-model.trim="lastName" class="focus:outline-primary border-2 rounded-sm px-2 py-1" type="text">
                    <span ref="lastNameWarning" class="text-sm text-red-400 font-medium"></span>
                </div>

                <div class="flex flex-col">
                    <label for="email">Email <span class="text-red-400">*</span></label>
                    <input id="email" @input="clearWarning(emailWarning)" v-model.trim="email" class="focus:outline-primary border-2 rounded-sm px-2 py-1" type="text">
                    <span ref="emailWarning" class="text-sm text-red-400 font-medium"></span>
                </div>

                <div class="flex flex-col">
                    <label for="phone">Phone # <span class="text-red-400">*</span></label>
                    <input id="phone" @input="clearWarning(phoneWarning)" v-model.trim="phone" class="focus:outline-primary border-2 rounded-sm px-2 py-1" type="text">
                    <span ref="phoneWarning" class="text-sm text-red-400 font-medium"></span>
                </div>
            </fieldset>

            <div>
                <div class="flex justify-between flex-wrap items-center" :class="{'text-red-400': message.length > 1000, 'text-neutral-400': message.length <= 1000}" >
                    <label for="message">Message <span class="text-red-400">*</span></label>
                    <div class="text-xs text-inherit">
                        <span class="text-inherit">{{ message.length }}</span> / <span class="text-inherit">1000</span>
                    </div>
                </div>
                <textarea @input="clearWarning(messageWarning)" v-model.trim="message" class="focus:outline-primary border-2 rounded-sm px-2 py-1 resize-y w-full" name="" id="message" rows="8"> </textarea>
                <span ref="messageWarning" class="text-sm text-red-400 font-medium"></span>
            </div>

            <button class="max-md:mx-auto max-md:max-w-[30rem] hover:bg-primary/80 duration-200 ml-auto w-full max-w-[200px] bg-primary text-white px-3 py-2 rounded-md">Submit</button>
        </form>
        <Modal @closeModal="toggleModal" :isOpen="isOpen" :modalHeader="modalHeader" :modalBody="modalBody" :isSuccess="isSuccess"/>
    </div>
</template>

<script setup>
import Modal from '@/components/Modal.vue'
import emailjs from '@emailjs/browser';
import { ref } from "vue";

const isOpen = ref(false)
const modalHeader = ref("")
const modalBody = ref("")
const isSuccess = ref(true)

const firstName = ref("")
const lastName = ref("")
const email = ref("")
const phone = ref("")
const message = ref("")

const firstNameWarning = ref()
const lastNameWarning = ref()
const emailWarning = ref()
const phoneWarning = ref()
const messageWarning = ref()

const setModalContent = (header, body, modalType) => {
    modalHeader.value = header
    modalBody.value = body 
    isSuccess.value =  modalType
}

const isAllowedToSubmit = () => {
    var lastSubmission = localStorage.getItem('lastSubmission');
    if (lastSubmission) {
        // Check if the last submission was more than 6 hours ago
        var lastSubmissionTime = new Date(lastSubmission).getTime();
        var currentTime = new Date().getTime();
        var timeDifference = currentTime - lastSubmissionTime;
        var hoursDifference = timeDifference / (1000 * 60 * 60);
        if (hoursDifference < 1) {
            // User has submitted within the last 6 hours
            return false;
        }
    }
    return true;
}

const sendEmail = (form) => {
    // const form = {
    //     message: "Lorem Ipsum",
    //     from_name: "Woody El Salva",
    //     from_email: "woody.sample@gmail.com",
    //     from_phone: "0941 229 8912",
    // }
  emailjs
    .send(process.env.VUE_APP_EMAILJS_SERVICE_ID, process.env.VUE_APP_EMAILJS_EMAIL_TEMPLATE, form, {
      publicKey: process.env.VUE_APP_EMAILJS_PUBLIC_KEY,
    })
    .then(
      () => {
        console.log('SUCCESS!');
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
};

const submitForm = async () => {
    if(!validateData(firstName.value, lastName.value, email.value, phone.value, message.value)) return

    if (isAllowedToSubmit()) {
        localStorage.setItem('lastSubmission', new Date());
        
        const form = {
            message: message.value,
            from_name: `${firstName.value} ${lastName.value}`,
            from_email: email.value,
            from_phone: convertToFormattedPhoneNumber(phone.value),
        }

        sendEmail(form)
        
        // Inform the user about successful submission
        setModalContent("Quote Request Submitted", "Thank you for your submission.", true)
        toggleModal()

        clearInputs()
    } else {
        // Inform the user about failed submission
        setModalContent("Failed", "You can only submit a request once every (1) one hour. Please try again later.", false)
        toggleModal()
    }
}

const clearInputs = () => {
    firstName.value = ""
    lastName.value = ""
    email.value = ""
    phone.value = ""
    message.value = ""
}

const validateData = (firstName, lastName, email, phone, message) => {
    clearWarning()

    if(phone.length > 0) phone = phone.replaceAll(" ", "")

    if(firstName.length == 0){
        showWarning(firstNameWarning.value, "First name is required.")
        return false
    }
    if(hasSpecialCharacter(firstName)){
        showWarning(firstNameWarning.value, "First name cannot contain special characters.")
        return false
    }

    if(lastName.length == 0){
        showWarning(lastNameWarning.value, "Last name is required.")
        return false
    }
    if(hasSpecialCharacter(lastName)){
        showWarning(lastNameWarning.value, "Last name cannot contain special characters.")
        return false
    }

    if(email.length == 0){
        showWarning(emailWarning.value, "Email is required.")
        return false
    }
    if(!isEmail(email)){
        showWarning(emailWarning.value, "Invalid email.")
        return false
    }

    if(phone.length == 0){
        showWarning(phoneWarning.value, "Phone # is required.")
        return false
    }
    if(!isValidPhoneNumber(phone)){
        showWarning(phoneWarning.value, "Invalid phone (ex. 09XX XXX XXXX).")
        return false
    }

    if(message.length == 0){
        showWarning(messageWarning.value, "Messsage is required.")
        return false
    }
    // if(hasSpecialCharacter(message)){
    //     showWarning(messageWarning.value, "Message cannot contain special characters.")
    //     return false
    // }
    if(message.length < 50){
        showWarning(messageWarning.value, "Message has to be at least 50 characters.")
        return false
    }
    if(message.length > 1000){
        showWarning(messageWarning.value, "Message has surpassed the maximum length allowed.")
        return false
    }

    return true
}

const showWarning = (el, message) => {
    el.innerText = message
}

const clearWarning = (target) => {

    if(!target){
        firstNameWarning.value.innerText = ""
        lastNameWarning.value.innerText = ""
        emailWarning.value.innerText = ""
        phoneWarning.value.innerText = ""
        messageWarning.value.innerText = ""
    }
    else{
        target.innerText = ""
    }
    
}

const hasSpecialCharacter = (str) => {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return regex.test(str);
}

const isEmail = (str) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(str);
}

const isValidPhoneNumber = (str) => {
    const regex = /^09\d{9}$/;
    const trimmedStr = str.replace(/\s/g, '');
    return regex.test(trimmedStr);
}

const removeConsecutiveSpaces = (str) => {
    return str.replace(/\s+/g, ' ');
}

function convertToFormattedPhoneNumber(input) {
    // Remove all non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, '');
    
    // Check if the input has 10 or 11 digits
    if (digitsOnly.length === 10 || digitsOnly.length === 11) {
        // Format the phone number with spaces
        return digitsOnly.slice(0, 4) + ' ' + digitsOnly.slice(4, 7) + ' ' + digitsOnly.slice(-4);
    } else {
        // If the input doesn't have 10 or 11 digits, return an error message
        return 'Invalid phone number format';
    }
}

const toggleModal = () => {
    isOpen.value = !isOpen.value
}
</script>
