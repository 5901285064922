<template>
    <div id="gallery" class="flex flex-col w-full max-w-[1200px] mx-auto gap-7">
        <h2 v-if="data" class="max-lg:px-5 text-balance w-full text-4xl font-semibold text-center">
            Our <span class="text-primary">gallery</span>
        </h2>

        <div v-else class="bg-neutral-200 rounded-md w-full max-w-64 h-16 mx-auto"></div>

        <template v-if="data">
            <div class="w-full gallery h-fit gap-5 px-3 justify-between">
                <a v-for="image, index in data.gallery" :key="index" :href="image" target="_blank" class="w-full">
                    <img :class="{'hidden': index+1 > limit}" class="shadow-center mx-auto rounded-md md:hover:scale-105 duration-200 aspect-video bg-neutral-200 object-cover" :src="image" alt="gallery image">
                </a>
            </div>
            <button v-if="limit < data.gallery.length" @click="seeMoreImages" class="text-primary font-semibold">See more</button>
        </template> 
        
        <template v-else>
            <div class="w-full gallery gap-5 px-3 place-content-center place-items-center">
                    <div class="w-full max-w-[300px] aspect-[2/3] rounded-md bg-neutral-200 animate-pulse" v-for="i in 4" :key="i"></div>
            </div>
            <div class="bg-primary/40 animate-pulse w-full max-w-32 h-10 rounded-md mx-auto"></div>
        </template>
    </div>
</template>

<script setup>
import { useGetResource } from '@/composables/useGetResource';
import { useGetResourcesLinks } from '@/composables/useGetResourcesLinks';
import { onMounted, ref } from 'vue';

const limit = ref(8)

const { resourcesLinks } = useGetResourcesLinks()
const { getData } = useGetResource(resourcesLinks.galleryUrl)
const data = ref(null)

onMounted(async () => {
    data.value = await getData()
})

const seeMoreImages = () => {
    limit.value += 4
}

</script>
