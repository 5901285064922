<template>
  <div id="overview" class="w-full relative p-5 overflow-hidden">
    <div
      class="pt-28 pb-16 max-sm:min-h-0 mx-auto max-w-[1200px] flex flex-nowrap items-center w-full min-h-screen"
    >
      <div class="max-lg:w-full w-1/2 flex flex-col gap-10">
        <div class="flex flex-col gap-3">
          <h1
            class="max-sm:text-5xl text-6xl text-white text-balance font-bold"
          >
            Let's Build Your Dream <span class="text-primary">Deck</span> Today!
          </h1>

          <p class="text-lg font-semibold text-white">
            Decking/Railing Installation & Repair.
          </p>
        </div>

        <div>
          <ul class="flex flex-col gap-3">
            <li class="text-lg flex gap-2 items-center">
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2rem"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span class="w-full text-wrap text-white"
                >Improving your property’s value and style.</span
              >
            </li>
            <li class="text-lg flex gap-2 items-center">
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2rem"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span class="w-full text-wrap text-white"
                >Revamp your outdoor living space.</span
              >
            </li>
            <li class="text-lg flex gap-2 items-center">
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2rem"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span class="w-full text-wrap text-white"
                >Personalized expert craftsmanship.</span
              >
            </li>
            <li class="text-lg flex gap-2 items-center">
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2rem"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span class="w-full text-wrap text-white"
                >Transform your space into a sanctuary of relaxation.</span
              >
            </li>
          </ul>
        </div>

        <div class="flex flex-col gap-3">
          <button
            @click="goTo('quote')"
            class="max-sm:max-w-none hover:bg-white hover:text-primary w-full max-w-44 font-semibold duration-200 text-white border-2 border-white rounded-lg px-5 py-2"
          >
            Get Started
          </button>
          <p class="text-white w-full max-w-96 text-pretty">
            Start by requesting a quote; it's free, fast, and hassle-free.
          </p>
        </div>
      </div>
    </div>

    <img
      loading="eager"
      class="max-lg:hidden translate-x-[100%] hero-from-right w-[45%] rounded-l-3xl border-4 border-white h-[500px] object-cover object-center absolute top-[50%] right-0 -translate-y-[50%]"
      src="./../../../assets/Hero.png"
      alt=""
    />

    <img
      loading="eager"
      class="w-full h-full object-cover object-center absolute top-0 left-0 z-[-2]"
      src="./../../../assets/Hero.png"
      alt=""
    />

    <div class="absolute top-0 left-0 z-[-1] w-full h-full bg-black/70">/</div>
  </div>
</template>

<script setup>
    import { useGoTo } from "@/composables/useGoTo";
    const { goTo } = useGoTo();


    
</script>
