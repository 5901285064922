import axios from 'axios';

export function useGetResource(url){
    const getData = async () => {
        try {
            // await new Promise(resolve => setTimeout(resolve, 5000));
            const response = await axios.get(url)
            // console.log(response.data)
            return response.data
        } catch (error) {
            console.error("Error fetching data: " + error)
            return null
        }
    }

    return { 
        getData
    }
}